import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Platform, SafeAreaView, StyleSheet } from 'react-native';
import PDFReader from '@luciapp/rn-pdf-reader-js';
import { OtcPdfReaderProps } from '../../../features/chat/screens/types';
import { Log } from '../../../utils/Log';
import { Headline, Portal, useTheme } from 'react-native-paper';
import { themeProp } from '../../../types/main';
import { LoadingBlocker } from '../Loading/LoadingBlocker';
import { useDownloadFile } from '../FileUpload/queries';
import { PdfReaderBar } from './PdfReaderBar';
import { AppTheme } from '../../../styles/theme';
import { useDownloadThenSaveOtcFile } from '../../../hooks/useOtcFile/useDownloadThenSaveOtcFile';
import { openPdfForWeb } from './utils/openPdfForWeb';
import { alert } from '../../../utils/alert';

export const OtcPdfReader = ({
  route: {
    params: { source, type, downloadable, fileName },
  },
  navigation,
}: OtcPdfReaderProps) => {
  const [documentSource, setDocumentSource] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme() as themeProp;
  const downloadOTCDocument = useDownloadFile();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const downloadThenSaveOtcFile = useDownloadThenSaveOtcFile();
  const isOnWeb = Platform.OS === 'web';

  useEffect(() => {
    /* @todo must be awaited */
    void downloadDocument();
  }, []);

  const downloadDocument = async () => {
    try {
      const downloadedSource = await downloadOTCDocument.mutateAsync({
        s3Path: source,
        uploadType: type,
      });
      if (isOnWeb) {
        setIsLoading(false);
        await openPdfForWeb(downloadedSource);
        navigation.goBack();
      } else {
        setDocumentSource(downloadedSource);
      }
    } catch (error) {
      setIsLoading(false);
      alert('Öffnen fehlgeschlagen', 'Diese PDF Datei kann leider nicht geöffnet werden');
      Log.error(error, { message: 'error while downloading PDF' });
    }
  };

  return (
    <>
      <PdfReaderBar
        downloadable={downloadable}
        downloadLink={documentSource}
        fileName={fileName ?? 'Dokument'}
        downloadThenSaveOtcFile={downloadThenSaveOtcFile}
      />
      <SafeAreaView style={styles.container}>
        {documentSource && !isOnWeb && (
          <PDFReader
            source={{
              uri: documentSource,
            }}
            withPinchZoom={true}
            withScroll={true}
            customStyle={{
              readerContainerZoomContainer: {
                display: 'none',
              },
            }}
            onError={Log.error}
            onLoadEnd={() => setIsLoading(false)}
          />
        )}
        <Portal>
          <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent}>
            <Headline
              style={{
                color: '#fff',
              }}
            >
              Dokument wird geladen.
            </Headline>
          </LoadingBlocker>
        </Portal>
      </SafeAreaView>
    </>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.colors.background,
      height: 400,
      width: '100%',
    },
  });

import {
  GetUserProfilesThatLoggedInV2Query as GetUserProfilesThatLoggedInQuery,
  useGetUserProfilesThatLoggedInV2Query as useGetUserProfilesThatLoggedInQuery,
  UserType,
} from '../../../../graphql/operations';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { RefreshControl, StyleSheet, Text, View } from 'react-native';

import { CommonLargeUserItem } from './CommonLargeUserItem';
import { KeyboardAwareSectionList } from 'react-native-keyboard-aware-scroll-view';
import { NoDataRefresh } from '../../../components/Common/Loading/NoDataRefresh';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { useStore } from '../../../stores/store';
import { useAppStatus } from '../../../hooks/common/appStatus';
import { useRefreshControlWithTimeout } from '../../../hooks/list/useRefreshControlWithTimeout';
import { useResponsiveDrawer } from '../../../router/drawer/hooks/useResponsiveDrawer';
import { useUploadPushNotificationToken } from '../../../hooks';
import { AppBar } from '../../../components/AppBar/AppBar';
import { Appbar } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { SearchBar } from '../../../components/SearchBar/SearchBar';
import { PlaceholderAppBarAction } from '../../../components/Common/AppBar/PlaceholderAppBarAction';

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    nameTxt: {
      flex: 1,
      fontWeight: '600',
      color: theme.customColors.text,
      fontSize: 22,
    },
    container: { height: '100%', backgroundColor: theme.colors.background },
  });

type UserListProps = {
  onPress: Parameters<typeof CommonLargeUserItem>[0]['onPress'];
  appBarTitle: string;
  onMenuPress?: Parameters<typeof CommonLargeUserItem>[0]['onMenuPress'];
  menuIcon?: Parameters<typeof CommonLargeUserItem>[0]['menuIcon'];
  menuItems?: React.ReactNode;
};

export const UserList = (props: UserListProps) => {
  const navigation = useNavigation();
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { userProfile } = useStore(s => ({
    userProfile: s.userProfile,
    pushTokens: s.pushTokens,
  }));
  const contacts = useGetUserProfilesThatLoggedInQuery({ userType: UserType.Employee });
  const [searchValue, setSearchValue] = useState<string>();
  const [searchIsShown, toggleSearchIsShown] = useReducer(previous => !previous, false);
  const { appMovedToView } = useAppStatus();

  useEffect(() => {
    if (appMovedToView) {
      void contacts.refetch();
    }
  }, [appMovedToView]);

  useUploadPushNotificationToken();

  const checkIfContactsOnlyContainTheUserThemselves = useCallback(
    (userProfiles?: GetUserProfilesThatLoggedInQuery['userProfilesV2'], userProfileId?: string) => {
      if (!userProfiles || !userProfileId) {
        return false;
      }
      if (
        userProfiles.userProfiles.length === 1 &&
        userProfiles.userProfiles.flatMap(item => item.userId).includes(userProfileId)
      ) {
        return true;
      }
      return false;
    },
    [],
  );

  const searchableContacts = useMemo(
    () =>
      Array.from(
        contacts.data?.userProfilesV2.userProfiles
          ?.filter(contact => {
            if (!searchValue) {
              return contact;
            }
            return (
              contact.userId !== userProfile?.userId &&
              searchValue &&
              (new RegExp(searchValue, 'i').test(contact.lastname ?? '') ||
                new RegExp(searchValue, 'i').test(contact.firstname ?? '') ||
                new RegExp(searchValue, 'i').test(contact.location ?? '') ||
                new RegExp(searchValue, 'i').test(contact.department ?? '') ||
                new RegExp(searchValue, 'i').test(contact.employeeFunction ?? '') ||
                new RegExp(searchValue, 'i').test(contact.businessUnit ?? ''))
            );
          })
          .reduce((map, contact) => {
            const firstLetter = contact.lastname?.charAt(0).toUpperCase();
            if (map.has(firstLetter)) {
              map.get(firstLetter)!.push(contact);
            } else {
              map.set(firstLetter, [contact]);
            }
            return map;
          }, new Map<string, GetUserProfilesThatLoggedInQuery['userProfilesV2']['userProfiles']>())
          .entries() ?? [],
      )
        .map(([firstLetter, groupedContacts]) => ({ title: firstLetter, data: groupedContacts }))
        .sort((a, b) => (a.title > b.title ? 1 : -1)) ?? [],
    [contacts.data, searchValue],
  );

  const { getResponsiveContentWidth } = useResponsiveDrawer({});
  const width = getResponsiveContentWidth();

  const renderItem = (renderProps: {
    item: GetUserProfilesThatLoggedInQuery['userProfilesV2']['userProfiles'][number];
  }) => {
    if (!renderProps?.item || userProfile?.userId === renderProps.item.userId) {
      return null;
    }
    return (
      <CommonLargeUserItem
        item={renderProps.item}
        ripple={true}
        onPress={props.onPress}
        onMenuPress={props.onMenuPress}
        menuIcon={props.menuIcon}
        searchValue={searchValue}
        width={width}
      />
    );
  };

  const renderContactsList = !(
    contacts.isLoading ||
    !contacts.data ||
    contacts.data.userProfilesV2.userProfiles.length <= 0 ||
    checkIfContactsOnlyContainTheUserThemselves(contacts.data.userProfilesV2, userProfile?.userId)
  );

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => contacts.refetch(),
    errorMessageToLog: `failed to refetch data for the user list`,
  });

  return (
    <View style={styles.container}>
      <AppBar
        title={props.appBarTitle}
        showSearch={searchIsShown}
        onPressSearch={toggleSearchIsShown}
        renderActionsLeftOfTitle={() =>
          navigation.canGoBack() ? (
            <Appbar.BackAction
              onPress={() => navigation.goBack()}
              color={theme.customColors.icon}
            />
          ) : (
            <PlaceholderAppBarAction />
          )
        }
        renderSearchBar={() => (
          <SearchBar
            onChangeSearchValue={setSearchValue}
            onPressCloseSearch={() => {
              setSearchValue(undefined);
              toggleSearchIsShown();
            }}
            searchInputValue={searchValue}
          />
        )}
      />
      {renderContactsList ? (
        <KeyboardAwareSectionList
          keyboardShouldPersistTaps="handled"
          sections={searchableContacts}
          keyExtractor={item => item!.userId!}
          renderItem={renderItem}
          renderSectionHeader={({ section: { title } }) => (
            <View style={{ padding: 5 }}>
              <Text style={styles.nameTxt}>{title}</Text>
            </View>
          )}
          extraData={searchableContacts}
          stickySectionHeadersEnabled={true}
          refreshControl={
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => void onRefresh()}
              tintColor={theme.customColors.refreshControlSpinner}
            />
          }
        />
      ) : (
        <NoDataRefresh
          /* @todo must be awaited */
          text={
            isRefreshing || contacts.isLoading
              ? ''
              : 'Es konnten leider keine Kontakte gefunden werden'
          }
          onPress={() => void onRefresh()}
          isLoading={isRefreshing || contacts.isLoading}
        />
      )}
    </View>
  );
};

import { StyleSheet, View } from 'react-native';
import { Avatar } from 'react-native-paper';
import { ImageSourcePropType } from 'react-native';
import React, { memo, useMemo } from 'react';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { ImageLoader } from '../../../components/ImageLoader/ImageLoader';
import { AppTheme, useAppTheme } from '../../../styles/theme';

type AvatarDisplayProps = {
  avatar: {
    otcPath?: ImageSourcePropType | string;
    initials?: string;
  };
  size: number;
  type: UploadType.GroupChatImage | UploadType.ProfilePicture | UploadType.TeamImage;
};

export const AvatarDisplay = memo(({ avatar, size, type }: AvatarDisplayProps) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  if (!avatar.otcPath) {
    return (
      <Avatar.Text
        label={avatar.initials ?? 'EB'}
        style={styles.avatar}
        color={theme.customColors.pureWhite}
        size={size}
      />
    );
  }

  return (
    <ImageLoader
      storageFilename={avatar.otcPath as string}
      type={type}
      renderOnLoad={uri => {
        return <Avatar.Image size={size} source={{ uri }} style={styles.avatar} />;
      }}
      renderOnError={() => (
        <Avatar.Text
          label={avatar.initials ?? 'EB'}
          style={styles.avatar}
          color={theme.customColors.pureWhite}
          size={size}
        />
      )}
      renderWhileLoading={() => <View />}
      // renderWhileLoading={() => (
      //   <Shimmer width={size} height={size} radius={200} color={theme.customColors.avatar} />
      // )}
    />
  );
});

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    avatar: {
      backgroundColor: theme.customColors.avatar,
      zIndex: 1,
    },
  });
